<template>
    <div class="h-full">

        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
            @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="filter-box">
                <div class="input-option">
                    <el-date-picker v-model="value" type="date" value-format="yyyy-MM-dd HH:mm:ss" :editable="false"
                        :clearable="false" placeholder="选择日期">
                    </el-date-picker>
                </div>
                <el-button type="primary" size="small" @click="search" class="fb-search"
                    v-right-code="'/sysinterfacelog/getinterfaceloglist'">搜索</el-button> <br />
            </div>

            <p slot="elList">
                <el-table ref="sysInterfaceLogListTable" :data="dataSource.Result" border
                    @selection-change="handleSelectionChange" highlight-current-row>

                    <el-table-column v-for="(col, index, count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
                        :prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
                        :fixed="index == 0 && (!config || !config.isDetailDisplay)"
                        :min-width="(!config || !config.isDetailDisplay) ? parseInt(col.Width) : 0"
                        v-if="col.Visible && (!config || !config.isDetailDisplay || (config.isDetailDisplay && index < 2))" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-if="col.FieldName === 'Platform'"><span type="text" @click="rowClick(scope.row)"
                                    style="text-decoration:underline;color:#1874CD;cursor:pointer">{{
                                        scope.row[col.FieldName] }}</span></span>
                            <!-- <span v-else-if="col.FieldName === 'RequestContent' || col.FieldName === 'ResponseContent'"></span> -->
                            <span v-else> {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                    <!--<el-table-column label="操作" :min-width="130">
                        <template slot-scope="scope">
                            <el-button @click="sendInterface(scope.row)" v-right-code="'Manage_Right_System_SysInterfaceLog_RetrySysLog'" size="small" type="text">重发</el-button>
                        </template>
                    </el-table-column>-->
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>

import df from '../../../../../scripts/displayformat.js';
export default {
    mounted() {
        this.value = df.FormatDate(new Date(), 'yyyy-MM-dd hh:mm:ss');
        //this.Event.$on("reloadSysLogPageList", () => this.reloadPageList());

        this.initialize();
    },
    data() {
        return {
            value: '',
            multipleSelection: [],
            queryParam: {
                PageIndex: 1,
                PageSize: 10
            },
            dataSource: {
                ColDefs: {},
                Result: [],
                TotalCount: 0
            },

        }
    },

    props: {
        config: {
            isDetailDisplay: false,
            isButtonlDisplay: false,
        },
        option: {}
    },
    methods: {
        reloadPageList: function () {
            this.initialize();
        },
        initialize() {
            this.onPageChange(this.queryParam);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.$emit("onCheckRow", this.multipleSelection);
        },
        //dateShort: function myfunction(d) {
        //    if (typeof (d) == "string") d = new Date(d * 1000);
        //    return (d.getFullYear() + "-" + ultZeroize(d.getMonth() + 1) + "-" + ultZeroize(d.getDate()));

        //},
        onPageChange(param) {
            var _this = this;
            this.queryParam = param;
            param.PageParams = { SearchLogTime: _this.value };
            //param.InterfaceTime = new Date(_this.value).toDateString();

            this.$ajax.query("omsapi/sysinterfacelog/getinterfaceloglist", "post", param, data => {
                _this.dataSource = data;
            });
        },
        onDataSourceChange(ds) {
            var _this = this;

            _this.dataSource = {
                ColDefs: {
                    BodyFieldParams: []
                },
                Result: [],
                TotalCount: 0
            };
            _this.$nextTick(function () {
                _this.dataSource = ds;

            });


        },
        search() {
            this.initialize();
        },
        rowClick(row) {
            this.$ajax.send("omsapi/sysinterfacelog/search", "get", { id: row.Id, date: this.value }, (data) => {
                this.Event.$emit("clearEditSysInterfaceLogForm");
                this.onChangeEditDataSource(data.Result);
            });
        },
        //sendInterface(row) {
        //    this.$confirm('重发？, 是否继续?', '提示', {
        //        confirmButtonText: '确定',
        //        cancelButtonText: '取消',
        //        type: 'warning',
        //        center: true
        //    }).then(() => {
        //        row.InterfaceName = row.LogID;
        //        row.RequestTime = new Date(this.value).toLocaleDateString();
        //        this.$ajax.send("omsapi/sysinterfacelog/send", "post", row, (data) => {
        //            console.log(data);
        //        });
        //        //this.$message({
        //        //    type: 'success',
        //        //    message: '成功!'
        //        //});
        //    }).catch(() => {
        //        this.$message({
        //            type: 'info',
        //            message: '已取消'
        //        });
        //    });
        //},
        onChangeEditDataSource(data) {
            this.config.isDetailDisplay = true;
            this.$emit("onChangeEditDataSource", data);
        }

    }

}
</script>
<style></style>